<template>
  <div id='payment-state-polling'>
    <div class="icon-wrapper"><icon-sand-clock /></div>
    <h1 class="title">{{ $t(titleKey) }}</h1>
    <span class="subtitle">{{ $t(subTitleKey) }}</span>
  </div>
</template>

<script>
import IconSandClock from '@/components/shared/elements/icons/IconSandClock';

export default {
  name: 'PaymentStatePolling',
  props: {
    titleKey: {
      type: String,
      required: false,
      default: 'pages.payment.finalization.states.polling.title',
    },
    subTitleKey: {
      type: String,
      required: false,
      default: 'pages.payment.finalization.states.polling.subtitle',
    },
  },
  components: {
    IconSandClock,
  },
  data: () => ({
  }),
  created() {},
  computed: {},
  methods: {},
};
</script>

<style lang='scss' scoped>
$iconWrapperSize: 135px;

#payment-state-polling {
  text-align: center;
}

.icon-wrapper {
  margin: 0 auto 25px;
  width: $iconWrapperSize;
  height: $iconWrapperSize;
  border-radius: 100%;
  background-color: $primaryBlue;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: $breakpointDownSm) {
    width: calc(#{$iconWrapperSize} - 15px);
    height: calc(#{$iconWrapperSize} - 15px);
  }

  .icon-sandclock {
    &::v-deep {
      svg {
        @media screen and (max-width: $breakpointDownSm) {
          width: 50px;
          height: 50px;
        }
      }
    }
  }
}

.title {
  color: $primaryBlue;
  font-weight: 700;
  font-size: 25px;
  margin: 0 0 5px;
  line-height: 1.2;

  @media screen and (max-width: $breakpointDownXs) {
    font-size: 20px;
  }
}

.subtitle {
  display: block;
  font-size: 15px;
  font-weight: 500;
}
</style>
