<template>
  <div class='icon-sandclock'>
    <svg id="sand-clock" xmlns="http://www.w3.org/2000/svg" width="71.205" height="71.205" viewBox="0 0 71.205 71.205">
      <g id="Group_3236" data-name="Group 3236" transform="translate(31.152 31.152)">
        <g id="Group_3235" data-name="Group 3235">
          <path id="Path_38095" data-name="Path 38095" d="M244.026,224a20.026,20.026,0,1,0,20.026,20.026A20.051,20.051,0,0,0,244.026,224Zm8.251,28.277a2.235,2.235,0,0,1-3.151,0l-6.675-6.675a2.241,2.241,0,0,1-.65-1.575v-8.9a2.225,2.225,0,1,1,4.45,0v7.979l6.026,6.026A2.227,2.227,0,0,1,252.277,252.277Z" transform="translate(-224 -224)" :fill="color"/>
        </g>
      </g>
      <g id="Group_3238" data-name="Group 3238" transform="translate(13.351 36.145)">
        <g id="Group_3237" data-name="Group 3237">
          <path id="Path_38096" data-name="Path 38096" d="M111.02,266.121c-.165-.187-.3-.4-.476-.579L104.9,259.9l-5.643,5.643A11.029,11.029,0,0,0,96,273.411v3.756h13.462c-.067-.734-.111-1.477-.111-2.23A24.3,24.3,0,0,1,111.02,266.121Z" transform="translate(-96 -259.904)" :fill="color"/>
        </g>
      </g>
      <g id="Group_3240" data-name="Group 3240" transform="translate(13.351 8.901)">
        <g id="Group_3239" data-name="Group 3239">
          <path id="Path_38097" data-name="Path 38097" d="M96,64v3.752a11.07,11.07,0,0,0,3.258,7.868l5.643,5.643,5.643-5.643a11.055,11.055,0,0,0,3.258-7.868V64Z" transform="translate(-96 -64)" :fill="color"/>
        </g>
      </g>
      <g id="Group_3242" data-name="Group 3242">
        <g id="Group_3241" data-name="Group 3241">
          <path id="Path_38098" data-name="Path 38098" d="M42.278,0H2.225a2.225,2.225,0,0,0,0,4.45H4.45v8.2a19.9,19.9,0,0,0,5.865,14.161l4.339,4.339-4.339,4.339A19.9,19.9,0,0,0,4.45,49.652v8.2H2.225a2.225,2.225,0,0,0,0,4.45l27.178,0a24.1,24.1,0,0,1-1.753-4.45l-18.749,0v-8.2a15.482,15.482,0,0,1,4.562-11.014l5.914-5.91a2.235,2.235,0,0,0,0-3.151l-5.914-5.91A15.475,15.475,0,0,1,8.9,12.652V4.45H35.6v8.2a15.482,15.482,0,0,1-4.562,11.014l-5.914,5.914a2.229,2.229,0,0,0,0,3.146l5.389,5.385a24.677,24.677,0,0,1,2.741-3.551l-3.409-3.409,4.339-4.343a19.877,19.877,0,0,0,5.865-14.156V4.45h2.225a2.225,2.225,0,0,0,0-4.45Z" :fill="color"/>
        </g>
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'IconSandClock',
  props: {
    color: {
      type: String,
      required: false,
      default: '#fff',
    },
  },
  components: {},
  data: () => ({
  }),
  created() {},
  computed: {},
  methods: {},
};
</script>

<style lang='scss' scoped>
</style>
